// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("./../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-projet-js": () => import("./../src/templates/projet.js" /* webpackChunkName: "component---src-templates-projet-js" */),
  "component---src-templates-projets-js": () => import("./../src/templates/projets.js" /* webpackChunkName: "component---src-templates-projets-js" */),
  "component---src-templates-catalogue-js": () => import("./../src/templates/catalogue.js" /* webpackChunkName: "component---src-templates-catalogue-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

